
  @media (min-width: 768px){
    .navbar-form {
      margin-top: 21px;
      margin-bottom: 21px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .navbar-nav > li > .dropdown-menu, .dropdown-menu{
      display: block;
      margin: 0;
      padding: 0;
      z-index: 9000;
      position: absolute;
      -webkit-border-radius: 10px;
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
      border-radius: 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      opacity: 0;
      -ms-filter: "alpha(opacity=0)";
      -webkit-filter: alpha(opacity=0);
      -moz-filter: alpha(opacity=0);
      -ms-filter: alpha(opacity=0);
      -o-filter: alpha(opacity=0);
      filter: alpha(opacity=0);
      -webkit-transform: scale(0);
      -moz-transform: scale(0);
      -o-transform: scale(0);
      -ms-transform: scale(0);
      transform: scale(0);
      -webkit-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      -moz-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      -o-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      -ms-transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
      transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);
  
    }
    .navbar-nav > li.open > .dropdown-menu, .open .dropdown-menu{
      -webkit-transform-origin: 29px -50px;
      -moz-transform-origin: 29px -50px;
      -o-transform-origin: 29px -50px;
      -ms-transform-origin: 29px -50px;
      transform-origin: 29px -50px;
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      opacity: 1;
      -ms-filter: none;
      -webkit-filter: none;
      -moz-filter: none;
      -ms-filter: none;
      -o-filter: none;
      filter: none;
      padding: 5px 10px 15px;
    }
    .dropdown .dropdown-menu{
      /*top: -30px;*/
      border-top: none !important;
    }
    .fixed-plugin .dropdown-menu:before,
    .fixed-plugin .dropdown-menu:after{
      content: "";
      display: inline-block;
      position: absolute;
      top: 50px;
      width: 16px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
    }
    .fixed-plugin .dropdown-menu:before{
      border-bottom: 16px solid rgba(0, 0, 0, 0);
      border-left: 16px solid rgba(0,0,0,0.2);
      border-top: 16px solid rgba(0,0,0,0);
      right: -16px;
    }
  
    .fixed-plugin .dropdown-menu:after{
      border-bottom: 16px solid rgba(0, 0, 0, 0);
      border-left: 16px solid #FFFFFF;
      border-top: 16px solid rgba(0,0,0,0);
      right: -15px;
    }
  }

  .offerBtn-mobile {
      font-size: 20px !important;
      color: #eee !important;
      background: #CA4907 !important;
      border-radius: 5px;
      border-color: white;
  }
  
  .offerBtn-mobile:hover {
    background: #EA5F0B !important;
  }
  
  .faq-q {
      color: #394949;
      font-weight: 400 !important;
  }
  
  .faq-a {
  margin-top: 10px !important;
  }
  .faq-bottom {
      font-weight: 400 !important;
  }
  .section-place {
    margin-top: 0px !important;
    padding: 30px 0 !important;
    min-height: 500px !important;
  }
  .section-functions {
    margin-top:50px;
    /*margin-bottom:50px;*/
    padding: 0  30px 30px;
  }
  
  .navbar-default {
    position: fixed !important;
    top: 0px !important;
    background: rgba(0, 0, 0, 0.75);
    color: #fff !important;
  }
  
  .navbar-default .logo-container{
    margin-top: 2px;
  }
  
  .navbar-default a:hover{
    color: #ccc !important;
  }
  .ipad {
    position: relative !important;
    margin-top: 50px!important;
    left: 0;
    height: auto !important;
    width: 100%!important;
  }
  .image-menu {
    position: relative !important;
    left: 0;
    height: auto !important;
    width: 75%!important;
  }
  
  @media (max-width:991px){
    .image-menu {
      display: none;
    }
    .parents-image {
      display: none;
    }
      .landing-page .section-no-padding .parallax {
      height: 1150px !important;
    }
  }
  
  @media (max-width:1200px){
    .landing-page .section-no-padding .parallax {
      height: 950px;
    }
  }
  
  .parents-image {
      width: 46% !important;
      float: left !important;
      position:relative !important;
      height: auto !important;
      margin-left: 4%;
  }
  .function-text {
    font-size: 18px !important;
  }
  
  .menuWrapper {
    box-shadow: 0 0 20px rgba(55,55,86,0.77);
    background-color: #fff;
  }
  
  .purple {
    color: #CA4907;
  }
  
  .rose {
    color: #CC08A7 !important;
  }
  .navbar-default {
  background: transparent!important;
  }
  .purple-wrapper {
    background-image: linear-gradient(69deg, #CA4907 , #6100B7);
    display: flex;
    height: 275px;
    width: 45%;
    position: absolute;
    box-shadow: 0px 5px 20px #00000029;
    border-radius: 0 50px 50px 0;
    top: 100px;
  }
  
  .grey-wrapper {
    background: #F8F8F8 0% 0% no-repeat padding-box;
    display: flex;
    height: 530px;
    width: 45%;
    position: absolute;
    border-radius: 0 50px 50px 0;
  }
  
  .dropdown-toggle::after {
    border-top: 0.3em solid transparent;
    border-left: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    border-right: 0;
    vertical-align: unset;
  }
  
  /* Make Dropdown icon point right when collapsed, with Browser default vertical alignment. */
  .collapsed.dropdown-toggle::after {
    border-top: 0.3em solid transparent;
    border-left: 0.3em solid;
    border-bottom: 0.3em solid transparent;
    border-right: 0;
    vertical-align: unset;
  }
  
  .pink-wrapper {
    background-image: linear-gradient(289deg, #EA5F0B 0%, #CA4907 100%);
    display: flex;
    height: 275px;
    width: 45%;
    position: absolute;
    right: 0%;
    box-shadow: 0px 5px 20px #00000029;
    border-radius:  50px 0 0 50px;
    top: 100px;
  }
  
  .section-presentation {
    position: relative;
  }
  .ipad-comp {
    /*top: 100px !important;*/
  }
  .ipad-phone {
    height: 200px !important;
    z-index: 9999;
    top: 40px !important;
    left: 20px !important;
  }
  
  .ipad-phone-right {
    height: 290px !important;
    z-index: 9999;
    top: 40px !important;
    left: 420px !important;
  }
  .functions {
    background: transparent radial-gradient(closest-side at 50% 50%, #F8F8F8 0%, #F1F1F8 100%) 0% 0% no-repeat padding-box;
  }
  .function-part {
    border: 1px solid #DEDEEB;
    border-radius: 20px;
    opacity: 1;
    letter-spacing: 0;
    color: #333333;
    text-align: right;
    padding: 15px;
    margin: 15px;
  }
   .function-part p {
      font-weight: bold !important;
      font-size: 25px;
      letter-spacing: 0;
      color: #EA5F0B;
      margin-top: 0px !important;
    }
  
  
  .function-part-reversed {
    text-align: left !important;
  }
  
  .faq-question  {
    justify-content: center;
    display: flex;
    border: 1px solid #DEDEEB;
    border-radius: 20px;
    opacity: 1;
    letter-spacing: 0;
    background: transparent linear-gradient(253deg, #6100B7 0%, #CA4907 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 20px #00000029;
    border-radius: 20px;
    color: white;  padding: 15px;
    margin: 15px;
    min-height: 150px;
  }
   .faq-question  p {
      font-weight: 500 !important;
      font-size: 19px;
      letter-spacing: 0;
      margin-top: 0px !important;
    }
  .faq-question img{
    height: 110px;
    padding: 20px;
  }
  .question-text {
    text-align: left;
    max-width: 350px;
    padding: 20px;
  }
  .faq-part {
    border: 1px solid #DEDEEB;
    border-radius: 20px;
    opacity: 1;
    letter-spacing: 0;
    color: #333333;
    padding: 15px;
    margin: 15px;
    min-height: 150px;
  }
   .faq-part p {
      font-weight: 500 !important;
      font-size: 19px;
      letter-spacing: 0;
      color: #333333;
      margin-top: 0px !important;
    }
    .info-part {
      margin-bottom: 20px;
      height: 100%;
      border-radius: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: column;
      letter-spacing: 0;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 200;
      padding: 20px 0;
    }
  
    .info-element {
      width: 300px;
      text-align: left;
      padding: 10px;
      letter-spacing: 0px;
      color: #CA4907;
    }
  
    .info-element a{
      letter-spacing: 0px;
      color: #CA4907;
    }
  
  
    .info-element img {
      padding: 15px;
      background-size: cover;
      height: 45px;
      width: 45px;
      margin-right: 5px;
    }
  .send-offerBtn{
    background: #CA4907 0% 0% no-repeat padding-box !important;
    border-radius: 20px !important;
    color: white !important;
    float: left;
    padding: 10px 80px;
    margin-left: -15px;
  }
  
  .main-desc {
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
  }
  
  .main-desc .bolder-desc {
    font-weight: 700;
    font-size: 14px;
    letter-spacing: 0;
    color: #333333;
    margin-top: 26px;
  }
  .main-desc .normal-desc {
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    color: #333333;
    max-width: 780px;
    margin: 12px auto 35px;
  }
  
  @media only screen and (min-width: 768px) {
    .desc-what {
      padding-top: 40px;
    }
    .desc-sec {
      margin-top: 60px;
        margin-bottom: 60px;
    }
  }
  
  @media only screen and (max-width: 992px) {
  
    .desc-what {
      text-align: left !important;
      padding: 10px;
      font-size: 20px;
    }
    .function-part {
      text-align: left !important;
    }
    .desc-sec {
      text-align: left !important;
      padding: 10px;
      font-size: 20px;
    }
  
  
    .pink-wrapper {
      opacity: 0.1;
    }
    .hidden-sm {
      display: none;
    }
  }
  
  .navbar-toggle .icon-bar {
      background: #aaa !important;
  }
  
  .menu-right-part {
    margin-left: 20px;
    border-left: 1px solid #D9D9D9;
  }
  
  .violet-offerBtn {
    background: #e94800 !important;
    border-radius: 10px !important;
    color: white !important;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0;
    border: none;
    opacity: 1;
    height: 100%;
    padding: 0 40px;
    display: inline-block;
    padding: 10px;
  }
  
  .violet-offerBtn:hover {
    background: #9E2B01 !important;
  }
  
  .functions-part img {
    height: 47px;
  }
  
  .functions-part {
    display: flex;
    /* align-content: center; */
    align-self: center;
    flex-direction: column;}
  
  p.fn-title-b {
    font-weight: 700 !important;
    font-size: 23px !important;
    letter-spacing: 0px;
    color: #333333;
    margin: 21px 0 0 0 !important;
  }
  p.fn-title {
    font-weight: 700 !important;
    font-size: 18px !important;
    letter-spacing: 0px;
    color: #333333;
    margin: 21px 0 0 0 !important;
  }
  p.fn-teaser {
    font-weight: 400 !important;
    font-size: 14px !important;
    letter-spacing: 0px;
    color: #333333;
    margin: 16px 0 24px 0 !important;
  }
  p.fn-teaser-b {
    font-weight: 400 !important;
    font-size: 16px !important;
    letter-spacing: 0px;
    color: #333333;
    margin: 16px 0 24px 0 !important;
  }
  .your-kindergartner h4 {
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-weight: 600 !important;
    font-size: 35px !important;
    margin: 0 0 31px 0;
  
  }
  
  .menu-icon {
    color: #EA5F0B;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    display: block;
    float: right;
    margin-left: 3px;
  }
  
  .rotate{
    -moz-transition: all 200ms linear;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
  }
  
  .open .rotate{
    -moz-transform:rotate(0deg);
    -webkit-transform:rotate(0deg);
    transform:rotate(0deg);
    margin-top: 4px;
  }
  
  @-moz-keyframes spin { 100% { -moz-transform: rotate(0deg); } }
  @-webkit-keyframes spin { 100% { -webkit-transform: rotate(0deg); } }
  @keyframes spin { 100% { -webkit-transform: rotate(0deg); } }
  
  @-moz-keyframes spinb { 100% { -moz-transform: rotate(180deg); } }
  @-webkit-keyframes spinb { 100% { -webkit-transform: rotate(180deg); } }
  @keyframes spinb { 100% { -webkit-transform: rotate(180deg); } }
  
  .your-kindergartner p {
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    font-weight: 400 !important;
    font-size: 14px !important;
    margin: 0 0 28px 0;
  }
  
  .your-kindergartner-wrapper {
    min-height: 500px;
    padding: 50px;
  }
  

  .functions-padding {
    padding: 100px 50px;
  }
  
  .carousel-control.left {
    background: none !important;
    width: 25px;
  }
  .carousel-control.right {
    background: none !important;
    width: 25px;
  }
  .carousel-inner {
    padding: 0!important;
  }
  
  .wrap {
    background: #fff;
    margin: 0 50px 50px;
    /*height: 380px;*/
    box-shadow: 0px 5px 20px #00000029;
    border-radius: 50px;
    padding: 25px;
  }
  /* .wrap i {
    float: left;
    width: 100%;
    border-radius: 30px;
    min-height: 300px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  } */
  .opion-single-photo {
    width: 100%;
    border-radius: 30px;
    min-height: 300px;
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .main-opinion-carousel-wrapper{
    display: flex;
    align-items: center;
  }
  .main-opinion-carousel-wrapper-inner {
    display: flex;
    flex-flow: column;
    margin-right: 70px;
    padding: 30px 0;
  
  }
  .main-opinion-carousel {
    text-align: left;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    margin-bottom: 15px;
    padding-bottom: 40px;
  }
  .main-opinion-carousel-author {
    text-align: left;
    letter-spacing: 0px;
    color: #CA4907;
  }
  .bold-author {
    text-align: left;
    letter-spacing: 0px;
    color: #CA4907;
  }
  
  .wrap-small-margin {
    margin: 50px 5px !important;
  }
  
  .wrap img {
    max-width: 100%;
  }
  
  .main-blog-teaser-text{
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    margin-top: 43px;
    margin-bottom: 35px;
    padding-bottom: 40px;
  }
  .blog-prev{
    border-radius: 30px;
  }
  .blog-prev-full {
    margin: 20px 0;
    border-radius: 30px;
  
  }
  .section-purple {
    background: transparent linear-gradient(258deg, #6100B7 0%, #CA4907 100%) 0 0 no-repeat padding-box !important;
    box-shadow: 0 5px 20px #00000029;
    opacity: 1;
    padding: 145px 0;
  }
  .section-purple h2 {
    color:#fff;
  }
  .pink-offerBtn {
    background: #EA5F0B;
    color: #fff;
    opacity: 1;
    border: none;
  }
  .white-offerBtn {
    color: #CA4907;
    background: #fff;
    opacity: 1;
    border: none;
  }
  .footer-element {
    text-align: left;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    margin: 0;
    padding: 0;
  }
  .footer-icon {
    float: left;
    border: 2px solid #707070;
    opacity: 1;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    margin-right: 20px;
    padding: 8px;
  }
  .footer-part {
    display: flex;
    margin: 10px 0;
    align-content: center;
    align-items: center;
  }
  .footer-link {
    text-align: left;
    letter-spacing: 0px;
    color: #CA4907;
    display:block;
  }

  
  .price-title {
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    margin: 50px 0 0;
    height: 72px;
  
  }
  .price-subtitle {
    margin: 10px 0 0;
    letter-spacing: 0px;
    color: #333333;
  }
  .price-teaser {
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-top: 30px;
    margin-bottom: 50px;
    height: 60px;
  }
  .price-price {
    margin: 0;
    letter-spacing: 0px;
    color: #333333;
  }
  .price-month {
    margin: 10px 0 50px;
    letter-spacing: 0px;
    color: #333333;
  }
  
  .price-wrapper{
    height: 500px;
  }
  
  @media (min-width: 1200px) {
  
    .no-padding-wrap {
      padding: 5px;
    }
  }
  
  @media (max-width: 991px) {
  
    .middle-flex-col {
      flex-direction: column;
    }
    .middle-flex-col-rev {
      flex-direction: column-reverse;
    }
    .grey-wrapper {
      width: 90%;
    }
  }
  
  .functions-main-wrapper {
    display: flex;
    height: calc(100vh - 100px);
  }
  .funtions-iphone {
    margin-top: 120px;
    left: 100px;
  }
  .functions-main-text {
    display: flex;
    align-items: center;
    padding: 0px;
    margin: auto;
    text-align: center;
    font-size: 30px;
  }
  
  .opinions-main-wrapper {
    padding: 100px 0;
    display: flex;
    text-align: center;
  }
  .opinions-main-text {
    display: flex;
    align-items: center;
    padding-top: 30px;
    max-width: 700px;
    margin: auto;
    text-align: center;
  }

  
  .functions-iphone {
    height: 350px !important;
    z-index: 9999;
    top: 50px !important;
    left: 20px !important;
  }
  .functions-page .fn-title {
    text-align: left;
    letter-spacing: 0px!important;;
    color: #333333!important;;
    opacity: 1!important;;
  }
  .functions-page .fn-teaser {
    text-align: left;
    letter-spacing: 0px !important;;
    color: #333333 !important;;
    opacity: 1;
  }
  
  /* Style the tab */
  .tab {
    overflow: hidden;
  }
  
  /* Style the buttons inside the tab */
  .tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 70px 0 37px;
    transition: 0.3s;
    font-size: 17px;
    border-bottom: 1px solid #D9D9D9;

  }
  @media (min-width: 993px) and (max-width: 1199px) {
    .tab button {
      font-size: 14px;
      border-bottom: 1px solid #D9D9D9;
  
    }
  }
  .hiw-tab img {
    height:48px;
    margin-bottom: 16px;
  }
  /* Change background color of buttons on hover */
  .tab button:hover {
    border-bottom: 3px solid #CA4907;
    padding: 70px 0 36px;
  
  }
  
  /* Create an active/current tablink class */
  .tab button.activeOffer {
    padding: 70px 0 36px;
    border-bottom: 3px solid #CA4907;
  }
  
  /* Style the tab content */
  .tabcontent {
    display: none;
    padding: 100px 15px;
    -webkit-animation: fadeEffect 1s;
    animation: fadeEffect 1s;
  }
  .tabcontent:first-child {
    display:flex!important;
  }
  
  /* Fade in tabs */
  @-webkit-keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  @keyframes fadeEffect {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  
  .how-it-works .hiw-teaser {
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    max-width: 770px;
    margin: auto;
    padding-top: 9px;
  }

  .wrap-min-margin {
margin: 0px auto;
  }
  .hiw-opinion-carousel-wrapper {
    padding: 40px 25px;
  }
  .hiw-opinions-wrapper {
    margin: 97px 0 40px;
  }
  .middle-flex{
    display: flex;
    align-items: center;
  }
  .hiw-faq-title {
    text-align: left;
    letter-spacing: 0px;
    font-weight: 700;
    color: #333333;
    padding: 0 0 20px 0;
    border-bottom: 1px solid #D9D9D9;
    opacity: 1;
  }
  .hiw-faq-carousel-wrapper {
    padding: 20px 25px;
  }
  
  .hiw-faq-part-icon {
    float: left;
    opacity: 1;
    border-radius: 50%;
    width: 16px;
    height: 10px;
    margin-right: 20px;
    margin-left: 20px;
  }
  
  .hiw-faq-part-text {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .from-home {
    padding-left: 125px;
  }
  
  .hiw-faq-part-title {
    text-align: left;
    font-weight: 700;
    letter-spacing: 0px;
    color: #333333;
    margin: 25px 0 0;
  }
  
  .wyg-faq-part-title{
    color: #000;
    margin: 5px 0 0;
    text-align: center;
    margin-top: 50px;
  }
  .wyg-faq-part-text{
    color: #333333;
    margin: 15px 0 0;
    display: flex;
    align-items: center;
  }
  
  .hiw-faq-part-info {
    text-align: left;
    letter-spacing: 0;
    color: #333333;
    margin-top: 5px !important;
  }
  
  .hiw-faq-part {
    border-bottom: 1px solid #D9D9D9;
  
  }
  .load-more-button-wrapper {
    text-align: center;
    padding:20px 50px;
    background: white;
    position: absolute;
    margin: auto;
    top: -40px;
    left: calc(50% - 135px);
  }
  .load-more-wrapper {
    border-bottom: 1px solid #D9D9D9;
    opacity: 1;
    position: relative;
    margin: 50px 0;
  }
  .input-half {
    width: calc(50% - 8px);
  }
  .section-contact-info {
    height: 550px;
  }
  
  .solution-wrapper {
    padding: 50px;
  }
  #contact_form{
    width: 100%;
    padding: 50px;
  }
  .additional-ipad-comp {
    left:50px;
    height: 300px !important;
  }
  #chat {
    display: flex;
  }
  .footer-icon-download {
    max-height: 50px;
    margin: 20px 10px 50px;
  }
  
  .footer-logo-wrapper {
    display: flex;
    justify-content: center;
  }
  .main-page-desc {
    margin-bottom: 100px !important;
  }
  .images-mac-iphone-100 {
    margin-top: 100px;
  }
  .order_text {
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  .order_text a{
    text-align: center;
    letter-spacing: 0px;
    color: #333333;
  }
  
  @media (max-width:992px) {
      .tab {
    margin: 5px auto;
    }

  }
  