.page-header {
  .carousel .carousel-indicators {
    bottom: 60px;
  }

  .container {
    color: #ffffff;
  }

  .title {
    color: $white-color;
  }

  &.header-small {
    height: 65vh;
    min-height: 65vh;

    .container {
      padding-top: 20vh;
    }
  }
}

.hero-shadow {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: block;
  left: 0;
  top: 0;
  content: "";
  background-color: rgba(16, 14, 60, 0.85);
}

.preFooterBtn {
  border-radius: 70px 70px 70px 70px;
  font-size: 0.85em;
  padding: 10px 20px;
  border: 1.5px solid #e94800;
  color: #e94800 !important;
  background: none !important;
  margin-top: 0px !important;
  margin-bottom: 50px;
}
.preFooterBtn:hover {
  background: rgba($color: #e94800, $alpha: 0.2) !important;
  text-decoration: none;
}

.header-filter {
  position: relative;

  .container {
    z-index: 2;
    position: relative;
  }

  .info-title,
  .info.info-horizontal .icon i,
  .card-pricing.card-plain ul li {
    color: $white-color;
  }
}
.clear-filter {
  &:before {
    display: none;
  }
}

.iframe-container iframe {
  width: 100%;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.3);
}

.header-1,
.header-2,
.header-3 {
  .wrapper {
    background: #cccccc;
  }
  .content-center {
    max-width: none !important;
  }
}

.header-2,
.header-1 {
  .page-header {
    .card {
      margin-top: 60px;
    }
  }
}
.logo-landing {
  z-index: 9999999;
}

.b2bProgram {
  color: white;
  margin-bottom: 0px;
  text-align: justify;
}

.logo-landing img {
  margin: 50px;
  width: 100px !important;
}
.landing {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  transition: 0.5s;
  flex-wrap: wrap;
}
.landingContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}
.landingButtons {
  margin-top: 50px;
  margin-right: 10px;
    z-index: 9999
}
@media (max-width: 846px) {
  .landingButtons {
    display: flex;
    gap: 25px;
    flex-direction: column;
    margin-right: 0px;
  }
}
.buttons {
  margin-bottom: 40px;
}

.bg-overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
}
.bg-overlay-lower {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.programCard:hover {
  background: #d75a09 !important;
  transform: translateY(-10px);
}

.program:hover {
  background-color: #2a2a2e !important;
  transform: translateY(-10px);
}

.orangeCard:hover {
  // background-color: rgba(28, 25, 25, 0.29) !important;
  // transform: translateY(-10px);
}

.boxik {
  max-width: 600px;
  //max-height: 500px;
  border-top: 1px solid rgb(237, 93, 1);
  border-bottom: 1px solid rgb(237, 93, 1);
  padding: 55px 20px;
  position: relative;
}

.programCard {
  display: flex;
  align-items: center;
  text-align: justify;
  justify-content: center;
  min-height: 280px;
}

.importantCard {
  width: 280px;
  height: 150px;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.importantCard h4 {
  font-size: 1.3em;
}
.program {
  background-color: #353539;
  color: white;
  min-width: 300px;
  min-height: 300px;
  padding: 20px;
}

.programIcons {
  margin-top: -50px;
  margin-bottom: -50px;
  width: 100%;
}

.programDiv {
  margin-top: -50px;
}
.offerLink {
  transition: background-color 0.3s;
}

.offerLink:hover {
  background-color: rgba(0, 0, 0, 0.35) !important;
}

.footer-logo {
  max-width: 150px;
}

.offerImages {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;
}

.info-offer {
  margin: 0px;
}

.orangeCard {
  border: 2px solid #ed5d01;
  color: white !important;
  max-width: 350px !important;
  min-height: 500px !important;
  margin: 30px;
}
.program p {
  color: white;
}

.icon-program {
  padding-bottom: 20px;
}

.icon-program img {
  width: 80px;
}

.lastPage {
  color: black;
  margin-left: 50px;
  letter-spacing: 10px;
  font-size: 1.8em;
}
.lastPageDescription {
  color: black;
  margin-left: 50px;
  font-size: 1.5em;
}

.partTitle {
  font-size: 30px;
}

@media (max-width: 767px) {
  .programDiv {
    margin-bottom: 20px;
  }
  .footerHeader {
    margin-top: 20px;
  }
  .lastImg {
    margin-bottom: 20px;
  }
  .info {
    padding: 0px !important;
  }
  .partTitle {
    //text-align: left !important;
  }
  .icon-program {
    margin-top: 40px;
  }
  .offerImages {
    margin-top: 50px;
    gap: 50px;
  }
}

@media (min-width: 768px) {
  .communication {
    margin-bottom: 59px !important;
  }
}

.preFooterTxt {
  margin-top: 20px !important;
  margin-bottom: 0px !important;
}

.header-3 {
  .btn {
    margin: 0;
  }
  h6 {
    margin-bottom: 0;
  }
}
.headerTitle2 {
  font-size: 40px;
  letter-spacing: 13px;
  position: absolute;
  top: 70%;
  right: 0;
  margin-left: 30px;
  text-transform: uppercase;
}

@media (max-width: 410px) {
  .offerWrap {
    transform: translateX(-25px);
  }
  .offerWrap1 {
    transform: translateX(-10px);
  }
}
@media (max-width: 767px) {
  .headerTitle {
    margin-left: 40px !important;
    font-size: 35px !important;
  }
  .b2bProgram {
    text-align: left;
  }
}

@media (max-width: 999px) {
  .headerTitle {
    font-size: 35px;
    letter-spacing: 11px;
    margin-left: 20px;
  }
}
@media (min-width: 1000px) and (max-width: 1199px) {
  .headerTitle {
    font-size: 46px;
    letter-spacing: 11px;
    margin-left: 20px;
    left: -4vw;
  }
}
@media (min-width: 1250px) {
  .headerTitle {
    left: -4vw;
  }
}

.boxContainer {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;
  width: 100%;
}

.boxContent {
  z-index: 1;
  min-height: 290px;
  text-align: center;
}

.boxContainer .iconBox {
  position: relative;
  width: 320px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 30px;
  transition: 0.5s;
}

//   .boxContainer .iconBox::before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 50px;
//     width: 50%;
//     height: 100%;
//     background: #fff;
//     border-radius: 8px;
//     transform: skewX(15deg);
//     transition: 0.5s;
//   }

//   .boxContainer .iconBox::after {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 50px;
//     width: 50%;
//     height: 100%;
//     background: #fff;
//     border-radius: 8px;
//     transform: skewX(15deg);
//     transition: 0.5s;
//     filter: blur(30px);
//     transition: 0.5s;
//   }

.boxContainer .iconBox:hover:before,
.boxContainer .iconBox:hover:after {
  transform: skewX(0deg);
  left: 20px;
  width: calc(100% - 90px);
}

.boxContainer .iconBox:nth-child(1):before,
.boxContainer .iconBox:nth-child(1):after {
  background: linear-gradient(315deg, #ff9d09, #ff2a00);
}

.boxContainer .iconBox:nth-child(2):before,
.boxContainer .iconBox:nth-child(2):after {
  background: linear-gradient(215deg, #ff9d09, #ff2a00);
}

.boxContainer .iconBox:nth-child(3):before,
.boxContainer .iconBox:nth-child(3):after {
  background: linear-gradient(100deg, #ff9d09, #ff2a00);
}

.boxContainer .iconBox span {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 50;
  pointer-events: none;
}

.boxContainer .iconBox .boxContent {
  position: relative;
  left: 0;
  padding: 20px 40px;
  z-index: 1;
  background: rgba(0, 0, 0, 0.75);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  backdrop-filter: blur(10px);
  z-index: 1;
  transition: 0.5s;
  color: #fff;
}

.programBusiness {
  margin-top: 20px;
  margin-bottom: 20px;
}

.boxContainer .iconBox:hover .boxContent {
  left: -25px;
  padding: 60px 40px;
}

.boxContainer .iconBox .boxContent h2 {
  font-size: 2em;
  color: #fff;
  margin-bottom: 10px;
}

.boxContainer .iconBox .boxContent p {
  font-size: 1.1em;
  line-height: 1.4em;
  margin-bottom: 10px;
}

@keyframes animate {
  0%,
  100% {
    transform: translateY(10px);
  }
  50% {
    transform: translateY(-10px);
  }
}

.programCard1 {
  text-align: center;
  color: white;
  display: flex;
  align-items: center;
  margin: auto;
  position: relative;
  border-radius: 35px;
  width: 400px;
  height: 200px;
  background: radial-gradient(
      93.62% 405.98% at 2.8% 5.47%,
      rgba(204, 88, 6, 0.1) 0%,
      rgba(198, 132, 8, 0) 100%
    ),
    rgba(121, 47, 4, 0.651);
  background-clip: padding-box;
  border-top: 0.5px solid #ff6f00;
  border-left: 0.5px solid #ff6f00;
  box-shadow: inset 0 0 50px rgba(175, 175, 175, 0.15),
    10px 10px 10px rgba(7, 7, 7, 0.05);
}
.bubble {
  margin-bottom: 50px;
}

.glow {
  box-shadow: -3px -3px 8px 1px #e06806, 3px 3px 8px 1px #e06806;
}

.card-important {
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23rem;
  height: 15rem;
  position: relative;
  background-color: #282a36;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 20px;
  transition: color 0.5s;
}
.card-important::after {
  content: "";
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #e13f03;
  background: -webkit-linear-gradient(top left, #e13f03, #db6426);
  background: -moz-linear-gradient(top left, #e13f03, #db6426);
  background: linear-gradient(to bottom right, #e13f03, #db6426);
  transform: translateY(90%);
  transition: ease 0.8s;
  height: 100%;
  border-radius: 0px 0px 10px 10px;
  z-index: 2000;
}
.card-important:hover {
  color: rgb(240, 239, 239);
}
.card-important:hover::after {
  transform: translateY(0%);
  color: black;
}
.card-important h4 {
  text-align: center;
  padding: 20px;
  z-index: 9999999;
}

.importantTitle {
  margin-top: 40px;
  font-size: 3.2em;
  // font-size: clamp(3rem, 15vmin, 8rem);
  color: white;
  font-family: "Electrolize", sans-serif;
  // font-family: sans-serif;
}

.underlined {
  /*   background: red; */
  position: relative;
}

.underline-overflow {
  display: inline-block;
  /* A little extra padding and overflow hidden   */
  overflow: hidden;
  padding-bottom: clamp(1rem, 2vmin, 2rem);
}
.underline-overflow:after {
  content: "";
  position: absolute;
  top: 100%;
  height: 240%;
  aspect-ratio: 3.7 / 1;
  left: 50%;
  transform: translate(-50%, -10%);
  border-radius: 50%;
  border: 6px solid rgb(237, 93, 1);
}

.cardBox {
  margin: 30px;
  font-size: 15px;
  position: relative;
  min-width: 500px;
  min-height: 300px;
  padding: 20px;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
    rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
}
.cardProgram {
  position: absolute;
  width: 95%;
  height: 95%;
  background: #000814;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
}

.cardBox::before {
  content: "";
  position: absolute;
  width: 20%;
  height: 270%;
  background: -webkit-linear-gradient(to right, #ff6200, #ff5e00, #d5510f);
  background: linear-gradient(to right, #ff6200, #ff5e00, #d5510f);
  animation: glowing01 15s linear infinite;
  transform-origin: center;
  animation: glowing 15s linear infinite;
}

.arrowUpContainer {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.arrowUpIcon {
  color: white;
  font-size: 48px;
  transition: color .5s;
}

.arrowUpIcon:hover {
  cursor: pointer;
  color: rgb(233, 72, 0)
}

.cardProgram span {
  text-align: justify;
  padding: 25px;
}

.programAlign {
  align-self: flex-end;
}

.contactImage {
  filter: grayscale(1) opacity(0.8);
  transition: filter 1s;
}

.contactImage:hover {
  filter: grayscale(0) opacity(1);
}

@keyframes glowing {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 667px) {
  .programBusiness {
    justify-content: center;
  }
  .programAlign {
    align-self: center;
  }
  .cardBox {
    min-width: 305px;
    min-height: 500px;
  }
  .programIcons {
    margin-top: 0px;
  }
}

.contactArrowUp {
  margin: 50px;
}


.space-btn {
  position: relative;
  font-family: "Electrolize", sans-serif;
  font-size: 1em;
  text-transform: uppercase;
  letter-spacing: 0.125em;
  line-height: 1;
  color: rgb(237, 93, 1);
  margin: 0;
  padding: 1em;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: lighdarten(rgb(237, 93, 1), 15%);
  }
  &:focus {
    color: lighten(rgb(237, 93, 1), 10%);
    outline: none;
  }
  &:active {
    color: rgb(237, 93, 1);
  }

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
  }

  &:before {
    top: 0;
    left: 0;
    box-shadow: inset 1px 1px 0 0 rgb(237, 93, 1);
  }
  &:after {
    right: 0;
    bottom: 0;
    box-shadow: inset -1px -1px 0 0 rgb(237, 93, 1);
  }
}

.space-btn:hover {
  &:before {
    animation: hoverShadowBefore 1s forwards;
  }
  &:after {
    animation: hoverShadowAfter 1s forwards;
  }
}

@keyframes hoverShadowBefore {
  0% {
    width: 100%;
    height: 1px;

    top: 0;
    left: 0;
  }
  33% {
    width: 1px;
    height: 100%;

    top: 0;
    left: 0;
  }
  66% {
    width: 1px;
    height: 1px;

    top: calc(100% - 1px);
    left: 0;
  }
  100% {
    width: 100%;
    height: 1px;

    top: calc(100% - 1px);
    left: 0;
  }
}

@keyframes hoverShadowAfter {
  0% {
    width: 100%;
    height: 1px;
  }
  33% {
    width: 1px;
    height: 100%;

    bottom: 0;
    right: 0;
  }
  66% {
    width: 1px;
    height: 1px;

    bottom: calc(100% - 1px);
    right: 0;
  }
  100% {
    width: 100%;
    height: 1px;

    bottom: calc(100% - 1px);
    right: 0;
  }
}

@media (min-width: 1140px) {
  .boxContainer {
    flex-direction: row;
  }
}

.cardBoxIcons {
  margin: 30px;
  font-size: 15px;
  position: relative;
  min-width: 330px;
  min-height: 500px;
  padding: 20px;
  display: grid;
  place-items: center;
  overflow: hidden;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 10px 0px,
    rgba(0, 0, 0, 0.5) 0px 2px 25px 0px;
}
.cardProgramIcons {
  position: absolute;
  width: 97%;
  height: 97%;
  background: #000814;
  border-radius: 20px;
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  color: #ffffff;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.5) 0px 18px 36px -18px inset;
}

.cardProgramIcons p {
  padding: 10px;
}

.cardBoxIcons::before {
  content: "";
  background: transparent;
  position: absolute;
  width: 100%;
  height: 270%;
  transition: background 15s;
}

.cardBoxIcons:hover::before {
  background: -webkit-linear-gradient(to right, #ff6200, #ff5e00, #d5510f);
  background: linear-gradient(to right, #ff6200, #ff5e00, #d5510f);
}

@media (min-width: 768px) {
  .info-offer {
    filter: grayscale(1);
    transition: filter 0.3s;
  }

  .info-offer:hover {
    filter: grayscale(0);
  }
}
