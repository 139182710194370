.blockquote{
    &.blockquote-info{
        border-color: #e94800;
        color: $info-color;

        small{
            color: $info-color;
        }
    }
}
