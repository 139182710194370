/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable {
    width: 100%;
}

th {
    text-transform: none !important;
    font-weight: normal;
}

.install {
    color: #ed5d01;
    font-weight: bold;
}

th:first-child {
    border-right: 1px solid #ed5d01;
    border-top: 0px solid black;
}

.responsiveTable td .tdBefore {
    display: none;
}

table.orangeTable {
    font-size: 15px;
    width: 100%;
    text-align: left;
}

table.orangeTable td:first-child {
    border-right: 1px solid #ed5d01
}
table.orangeTable td, table.orangeTable th {
    padding: 15px 10px;
}

.headers {
    border-top: 1px solid #ed5d01 !important;
}

table.orangeTable thead th:first-child {
    border-left: none;
}


table.orangeTable tfoot td {
    font-size: 13px;
}
table.orangeTable tfoot .links {
    text-align: right;
}


@media screen and (max-width: 63em) {
    /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none;, for accessibility)
    */

    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
        display: block;
    }

    .responsiveTable thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
        border-bottom: 2px solid #333;
    }

    .install {
        display: none !important;
    }

    .responsiveTable tbody tr {
        border: 1px solid #ed5d01;
        padding: .25em;
    }

    .responsiveTable td.pivoted {
        /* Behave like a "row" */
        border: none !important;
        position: relative;
        padding-left: calc(50% + 10px) !important;
        text-align: left !important;
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }

    .responsiveTable td .tdBefore {
        /* Now like a table header */
        position: absolute;
        display: block;

        /* Top/left values mimic padding */
        left: 1rem;
        width: calc(50% - 20px);
        white-space: pre-wrap;
        overflow-wrap: break-word;
        text-align: left !important;
        font-weight: 600;
    }
    .pilot {
        text-align: center !important;
        color: #ed5d01 !important;
    }
}

@media screen and (min-width: 63em) {
    .pilot {
        display: none;
    }
}

@media screen and (min-width: 30em) {
    /*.specification {*/
    /*    letter-spacing: 5px;*/
    /*}*/
}