.testimonials-1{
    .description{
        margin-bottom: 50px;
    }
}

.section-testimonials,
.testimonials-2{
    height: 490px;
    padding-bottom: 250px ;
        background-image: none;

        .card-profile{
            .card-title,
            .card-description{
                text-align: left !important;
            }
        }
        .card-image{
            margin: 0;
        }
        .carousel{
            .carousel-inner{
                box-shadow: none;
            }

            .carousel-control-prev,
            .carousel-control-next{
                i{
                    color: $light-black;
                }
            }
        }
        .testimonials-people{
            position: relative;

            img{
                position: absolute;
                border-radius: 50%;
                z-index: 4;
            }
            .left-first-person{
                left: 2%;
                top: 2%;
                height: 70px;
                width: 70px;
            }
            .left-second-person{
                left: 65%;
                top: 100%;
                height: 70px;
                width: 70px;
            }
            .left-third-person{
                left: -25%;
                top: 135%;
                height: 120px;
                width: 120px;
            }
            .left-fourth-person{
                left: 40%;
                top: 180%;
                height: 40px;
                width: 40px;
            }
            .left-fifth-person{
                left: 95%;
                top: 220%;
                height: 45px;
                width: 45px;
            }
            .left-sixth-person{
                left: 40%;
                top: 265%;
                height: 95px;
                width: 95px;
            }
            .right-first-person{
                right: 2%;
                top: 0;
                height: 60px;
                width: 60px;
            }
            .right-second-person{
                right: 30%;
                top: 60%;
                height: 70px;
                width: 70px;
            }
            .right-third-person{
                right: 95%;
                top: 95%;
                height: 50px;
                width: 50px;
            }
            .right-fourth-person{
                right: 66%;
                top: 145%;
                height: 40px;
                width: 40px;
            }
            .right-fifth-person{
                right: 90%;
                top: 210%;
                height: 100px;
                width: 100px;
            }
            .right-sixth-person{
                right: 15%;
                top: 240%;
                height: 70px;
                width: 70px;
            }
        }
    }
    @media (max-width: 576px) {
        .testimonials-2{
            height: 750px !important;
    }
}

    @media (max-width: 598px) {
        .testimonials-2{
            height: 750px;
            // padding-bottom: 250px;
    }
}

@media (min-width: 599px) and (max-width: 1199px) {
    .testimonials-2{
        height: 550px;
    }
}