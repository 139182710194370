/* // NOTE: styles are included via {<link />} tag at {index.html}
/* @import "~leaflet/dist/leaflet.css";
@import "~react-leaflet-markercluster/dist/styles.min.css"; */

.markercluster-map {
    height: 90vh;
  }
  .markercluster-map2 {  
    height: 40vh;
  }
  .marker-cluster-small {
    background-color: rgba(83, 130, 210, 0.6);
  }
  .marker-cluster-small div {
    background-color: rgba(60, 110, 219, 0.621);
  }
  .marker-cluster-medium {
    background-color: rgba(246, 123, 47, 0.623);
  }
  .marker-cluster-medium div {
    background-color: rgba(249, 110, 17, 0.639);
  }
  .marker-cluster-large {
    background-color: rgba(165, 98, 177, 0.6);
  }
  .marker-cluster-large div {
    background-color: rgba(184, 72, 209, 0.636);
  }
  .leaflet-oldie .marker-cluster-small {
    background-color: #b5e28c;
  }
  .leaflet-oldie .marker-cluster-small div {
    background-color: #6ecc39;
  }
  .leaflet-oldie .marker-cluster-medium {
    background-color: #f1d357;
  }
  .leaflet-oldie .marker-cluster-medium div {
    background-color: #f0c20c;
  }
  .leaflet-oldie .marker-cluster-large {
    background-color: #fdfd73;
  }
  .leaflet-oldie .marker-cluster-large div {
    background-color: #d8f117;
  }
  .marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
  }
  .marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 5px;
    margin-top: 5px;
    text-align: center;
    border-radius: 15px;
    font: 12px "Helvetica Neue", Arial, Helvetica, sans-serif;
  }
  .marker-cluster span {
    line-height: 30px;
  }
  .leaflet-cluster-anim .leaflet-marker-icon,
  .leaflet-cluster-anim .leaflet-marker-shadow {
    -webkit-transition: -webkit-transform 0.3s ease-out, opacity 0.3s ease-in;
    -moz-transition: -moz-transform 0.3s ease-out, opacity 0.3s ease-in;
    -o-transition: -o-transform 0.3s ease-out, opacity 0.3s ease-in;
    transition: transform 0.3s ease-out, opacity 0.3s ease-in;
  }
  .leaflet-cluster-spider-leg {
    -webkit-transition: -webkit-stroke-dashoffset 0.3s ease-out,
      -webkit-stroke-opacity 0.3s ease-in;
    -moz-transition: -moz-stroke-dashoffset 0.3s ease-out,
      -moz-stroke-opacity 0.3s ease-in;
    -o-transition: -o-stroke-dashoffset 0.3s ease-out,
      -o-stroke-opacity 0.3s ease-in;
    transition: stroke-dashoffset 0.3s ease-out, stroke-opacity 0.3s ease-in;
  }
  