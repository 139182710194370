.card{
  border: 0;
  border-radius: $border-radius-small;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 30px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

  .card-header{
    background-color: $transparent-bg;
    border-bottom: 0;
    background-color: transparent;
    border-radius: 0;
    padding: 0;
  }

  .card-footer{
    padding: 0;
    margin-top: 15px;
    background-color: $transparent-bg;
    border:0;
  }

  &[data-background-color="orange"]{
      background-color: $primary-color;
  }

  &[data-background-color="red"]{
      background-color: $danger-color;
  }

  &[data-background-color="yellow"]{
      background-color: $warning-color;
  }

  &[data-background-color="blue"]{
      background-color: $info-color;
  }

  &[data-background-color="green"]{
      background-color: darken($success-color, 5%);
  }
}

.card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 20px 0;
}

.card-container .cardOffer {
  position: relative;
  min-width: 320px;
  height: 440px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

.card-container .cardLogin {
  position: relative;
  min-width: 320px;
  height: 240px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
    inset -5px -5px 15px rgba(255, 255, 255, 0.1),
    5px 5px 15px rgba(0, 0, 0, 0.3), -5px -5px 15px rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  margin: 30px;
  transition: 0.5s;
}

.card-container .cardLogin:nth-child(1) .box .content a {
  background: #CA4907
}

 .card-container .cardLogin:nth-child(2) .box .content a {
  background: #CA4907
}


.card-container .cardLogin .box {
  position: absolute;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.card-container .cardLogin .box:hover {
  background: rgba(0, 0, 0, 0.883);
}

.card-container .cardLogin .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.card-container .cardLogin .box .content {
  padding: 20px;
  text-align: center;
}

 .card-container .cardLogin .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.card-container .cardLogin .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

 .card-container .cardLogin .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.909);
  z-index: 1;
  transition: 0.5s;
}

 .card-container .cardLogin .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: rgb(0, 0, 0);
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.card-container .cardLogin .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  color: white;
  background: #EA5F0B
}

 .card-container .cardOffer:nth-child(1) .box .content a {
  background: #CA4907
}

 .card-container .cardOffer:nth-child(2) .box .content a {
  background: #CA4907
}


.card-container .cardOffer .box {
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  bottom: 20px;
  background: #2a2b2f;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
}

.card-container .cardOffer .box:hover {
  background: rgba(0, 0, 0, 0.883);
}

.card-container .cardOffer .box:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.03);
}

.card-container .cardOffer .box .content {
  padding: 20px;
  text-align: center;
}

 .card-container .cardOffer .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8rem;
  color: rgba(255, 255, 255, 0.1);
}

.card-container .cardOffer .box .content h3 {
  font-size: 1.8rem;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  margin-bottom: 15px;
}

 .card-container .cardOffer .box .content p {
  font-size: 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.909);
  z-index: 1;
  transition: 0.5s;
}

 .card-container .cardOffer .box .content a {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: rgb(0, 0, 0);
  border-radius: 5px;
  text-decoration: none;
  color: white;
  margin-top: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transition: 0.5s;
}
.card-container .cardOffer .box .content a:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.6);
  color: white;
  background: #EA5F0B
}

@media (min-width: 768px) {
  .card {
    width: 100%;
  }
}